<template>
  <v-container class="contact mb-12">
    <h2>Contact Us</h2>
    <v-row>
      <v-col class="col-12 col-md-6">
        <v-card hover color="rgba(0,55,103,0.2)" class="pa-3 mb-6">
            <p class="subheading font-weight-regular">
              411 N Osage<br>
              Derby, Kansas 67037<br>
              Phone (316)788-1907<br>
              Fax (316)788-1941
            </p>
        </v-card>
        <v-card hover color="rgba(0,55,103,0.2)" class="pa-3">
          <p class="subheading font-weight-regular">
              <strong>Appointment Hours*</strong><br>
              Monday 11am-7pm<br>
              Tuesday 8am-7pm<br>
              Wednesday 11am-7pm<br>
              Thursday 11am-7pm<br>
              Closed Friday, Saturday, and Sunday
            </p>
            <p class="subheading font-weight-regular">
              *Other hours may be available depending on provider. 
            </p>
        </v-card>
      </v-col>
      <v-col class="col-12 col-md-6">
         <v-card hover color="transparent">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3163.3164639709285!2d-97.2470611!3d37.5476072!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87baf0344584e173%3A0x7d5c8faec0dd874c!2s411%20N%20Osage%20Rd%2C%20Derby%2C%20KS%2067037!5e0!3m2!1sen!2sus!4v1679686624678!5m2!1sen!2sus" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    //
  })
};
</script>
